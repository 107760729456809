import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useActivityIngredients } from 'lib/custom_hooks';
import {
  deleteBin,
  fetchActivityIngredientProgressesSilent,
  submitBins,
  searchBins,
  updateBin,
  fetchBins,
} from 'redux/productionProgress/rth/actions';
import { BinContext } from 'lib/providers/BinProvider';

const useBins = () => {
  const dispatch = useDispatch();
  const { activity, ingredientId } = useParams();
  const { getMealIdsByIngredientId } = useActivityIngredients();

  const {
    bins,
    fetching,
    fetchingError,
    submitting,
    submittingError,
    setBins,
    setFetchingError,
    setSubmittingError,
    setFetching,
    setSubmitting,
    resetState,
  } = useContext(BinContext);

  const reloadIngredientProgresses = async () => {
    const mealIds = getMealIdsByIngredientId(ingredientId);

    await dispatch(
      fetchActivityIngredientProgressesSilent({
        activity: activity,
        ingredientId: ingredientId,
        mealIds,
      })
    );
  };

  const onDecant = async (binData, sublotId) => {
    setFetching(true);
    setSubmittingError(null);

    const formData = {
      bins: binData.map((bin) => ({
        weightInLbs: bin.weightInLbs,
        binAssignmentsAttributes: [{ sublotId }],
      })),
    };

    const response = await dispatch(submitBins(formData));

    if (response.ok) {
      reloadIngredientProgresses();
    } else {
      setSubmittingError(response.error);
    }

    setFetching(false);

    return response;
  };

  const onDeleteBin = async (binId) => {
    setFetching(true);
    setSubmittingError(null);

    const response = await dispatch(deleteBin(binId));

    if (response.ok) {
      reloadIngredientProgresses();
    } else {
      setSubmittingError(response.error);
    }

    setFetching(false);

    return response;
  };

  const onSearch = async (searchTerm) => {
    setFetching(true);
    setFetchingError(null);

    const response = await dispatch(searchBins(searchTerm));

    if (response.ok) {
      setBins(response.bins);
    } else {
      setFetchingError(response.error);
    }

    setFetching(false);

    return response;
  };

  const onFetchBins = async (params) => {
    setFetching(true);
    setFetchingError(null);
    setBins([]);

    const response = await dispatch(fetchBins(params));

    if (response.ok) {
      setBins(response.bins);
    } else {
      setFetchingError(response.error);
    }

    setFetching(false);

    return response;
  };

  const onUpdateBin = async (
    { binId, binParams },
    reloadProgresses = false
  ) => {
    setSubmitting(true);
    setSubmittingError(null);

    const response = await dispatch(updateBin({ binId, binParams }));

    if (response.ok) {
      const newBins = bins.map((bin) =>
        bin.id === binId ? { ...response.bin } : bin
      );

      setBins(newBins);
      reloadProgresses && reloadIngredientProgresses();
    } else {
      setSubmittingError(response.error);
    }

    setSubmitting(false);

    return response;
  };

  const onUseBin = async (binId) => {
    await onUpdateBin({ binId, binParams: { inUse: true } });
  };

  const resetBinsErrors = () => {
    setFetchingError(null);
    setSubmittingError(null);
  };

  return {
    bins,
    fetching,
    fetchingError,
    submitting,
    submittingError,
    onDecant,
    onDeleteBin,
    onSearch,
    onFetchBins,
    onUpdateBin,
    onUseBin,
    resetBinsErrors,
    resetBins: resetState,
  };
};

const BIN_WEIGHT_MIN = 1;
const BIN_WEIGHT_MAX = 499;

export const binValidationHelpers = {
  minWeight: BIN_WEIGHT_MIN,
  maxWeight: BIN_WEIGHT_MAX,
  invalidWeight: (binWeight) =>
    binWeight !== '' &&
    (binWeight < BIN_WEIGHT_MIN || binWeight > BIN_WEIGHT_MAX),
  invalidWeightMessage: `Bin weight must be between ${BIN_WEIGHT_MIN} and ${BIN_WEIGHT_MAX} lbs`,
};

export default useBins;
