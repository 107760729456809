let x;
let allKeyFrames = {};
let otherStyles = {};
let penguinStyles = {};

for (x = 0; x <= 70; x++) {
  const delay = Math.floor(Math.random() * 0.2);
  const duration = Math.floor(Math.random() * 70) + 5;
  const left = Math.floor(Math.random() * 100);
  const top = 100;
  allKeyFrames[`@keyframes emoji-${x}`] = {
    '0%': {
      transform: 'rotate(0deg)',
      left: `${left}%`,
      top: `-${top}%`,
    },
    '25%': {
      left: `${left + 2}%`,
    },
    '50%': {
      left: `${left}%`,
    },
    '75%': {
      left: `${left - 2}%`,
      opacity: '1',
    },
    '100%': {
      transform: 'rotate(360deg)',
      top: '98%',
      opacity: '0',
    },
  };

  otherStyles[`&:nth-of-type(${x})`] = {
    animationName: `$emoji-${x}`,
    animationDelay: `${delay}s`,
    animationDuration: `${duration}s`,
    animationIterationCount: 'infinite',
    left: `${left}%`,
    top: `${top}%`,
    overflow: 'hidden',
  };
}

allKeyFrames['@keyframes penguin'] = {
  '0%': {
    transform: 'scale(-0.35, .35)',
    left: '-200px',
    top: '500px',
  },
  '25%': {
    transform: 'scale(-0.35, .35)',
    left: '200px',
    top: '600px',
  },
  '35%': {
    transform: 'scale(.35, .35)',
    left: '200px',
    top: '600px',
  },
  '50%': {
    transform: 'scale(.35, .35)',
    left: '-100px',
    top: '650px',
  },
  '75%': {
    transform: 'scale(-.35, .35)',
    left: '-100px',
    top: '650px',
  },
  '100%': {
    transform: 'scale(-0.35, .35)',
    left: '200px',
    top: '900px',
  },
};

penguinStyles['&:nth-of-type(1)'] = {
  animationName: 'penguin',
  animationDelay: '0s',
  animationTimingFunction: 'linear',
  animationDuration: '5s',
  animationIterationCount: 'infinite',
  overflow: 'hidden',
};

export default {
  // larger images
  willie: {
    background:
      'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/winter_rabbit.png") no-repeat right bottom',
    backgroundSize: 'contain',
    position: 'absolute',
    bottom: '75px',
    right: '70px',
    zIndex: '-2',
    width: '100%',
    height: '200px',
    minHeight: '175px',
    WebkitTransform: 'translate3d(0,0,0.01)',
    transform: 'translate3d(0,0,0.01)',
  },
  skatingPenguin: {
    position: 'absolute',
    '&:after': {
      content:
        'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/penguin.png")',
    },
    animationName: '$penguin',
    animationDelay: '0s',
    animationDuration: '5s',
    animationIterationCount: 'infinite',
    overflow: 'hidden',
    ...penguinStyles,
  },
  winterTree: {
    background:
      'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/winterTree2.png") no-repeat right bottom',
    backgroundSize: 'contain',
    position: 'absolute',
    bottom: '0',
    left: '0',
    zIndex: '-3',
    width: '100%',
    height: '700px',
    border: '0 none transparent',
    outline: '0 none transparent',
  },
  snowMan: {
    background:
      'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/snowman.PNG") no-repeat top left',
    backgroundSize: 'contain',
    position: 'absolute',
    top: '250px',
    left: '90px',
    zIndex: '-3',
    width: '25%',
    height: '25%',
    border: '0 none transparent',
    outline: '0 none transparent',
  },
  // snowflakes
  snowflake0: {
    position: 'absolute',
    '&:after': {
      content:
        'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/snowflake0.png")',
    },
    ...otherStyles,
  },
  snowflake1: {
    position: 'absolute',
    '&:after': {
      content:
        'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/snowflake1.png")',
    },
    ...otherStyles,
  },
  snowflake2: {
    position: 'absolute',
    '&:after': {
      content:
        'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/snowflake2.png")',
    },
    ...otherStyles,
  },
  ...allKeyFrames,
};
