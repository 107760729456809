import { GREY_100, ROBOTO, GREY_800, BLACK } from 'lib/constants';

import { largeFontSize, smallFontSize } from '../styles';

export const subInfo = {
  color: GREY_800,
  fontFamily: ROBOTO,
  fontSize: smallFontSize,
  fontWeight: 400,
};
export default {
  actionCell: {
    verticalAlign: 'top',
    width: '25%',
  },
  collapseWrapper: {
    padding: '16px 20px 16px 20px',
  },
  detailsButton: {
    fontFamily: ROBOTO,
    fontWeight: 900,
  },
  detailsRow: {
    backgroundColor: GREY_100,
  },
  titleCardDetailItem: {
    display: 'flex',
    paddingTop: '5px',
  },
  titleCardDetailsSection: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  detailsRowHidden: {
    backgroundColor: GREY_100,
    display: 'none',
    transition: 'display 250ms allow-discrete',
  },
  title: {
    letterSpacing: '1px',
    fontFamily: ROBOTO,
    fontSize: largeFontSize,
    fontWeight: 900,
  },
  binCellContainer: {
    display: 'flex',
  },
  detailValue: {
    color: GREY_800,
    fontFamily: ROBOTO,
    fontSize: smallFontSize,
    fontWeight: 400,
  },
  detailTitle: {
    color: BLACK,
    fontFamily: ROBOTO,
    fontSize: smallFontSize,
    fontWeight: 900,
    paddingRight: '8px',
    verticalAlign: 'top',
  },
};
