import {
  MEDIA_QUERY_PHONE_SCREEN,
  MEDIA_QUERY_TABLET,
  BLACK,
} from 'lib/constants';

export default {
  root: {
    fontFamily: 'Muli',
    width: 'auto',
    padding: '10px',
  },
  heading: {
    color: BLACK,
    marginTop: '20px',
    marginBottom: '1.3rem',
    fontWeight: '900',
    lineHeight: '1.1',
    fontSize: '24px',
    padding: '5px',
  },
  wrapper: {
    position: 'absolute',
    width: '100%',
    minHeight: '100%',
    background:
      'url("https://s3-us-west-2.amazonaws.com/asset.homechef.com/winter.png") no-repeat',
    zIndex: '-3',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    overflow: 'hidden',
  },
  clouds: {
    background:
      'url("https://s3-us-west-2.amazonaws.com/asset.homechef.com/bg-clouds2-tinypng.png") repeat-x 0 bottom #ACE6FF',
    width: '100%',
    height: '230px',
    minHeight: '230px',
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: '-4',
    WebkitTransform: 'translate3d(0,0,0.01)',
    transform: 'translate3d(0,0,0.01)',
  },
  grid: {
    zIndex: '1',
    marginLeft: '30px',
  },

  [MEDIA_QUERY_PHONE_SCREEN]: {
    root: {
      // adding in the 175px to the bottom so the Valentine's dog (Hugh)
      // shows up on the scanners at the bottom
      padding: '15px 15px 175px 15px',
      fontFamily: 'Muli',
    },
    heading: {
      fontSize: '14px',
      margin: '10px 0px',
    },
    firstHeading: {
      marginTop: '20px',
    },
  },

  [MEDIA_QUERY_TABLET]: {
    root: {
      // adding in the 250px to the bottom so the Valentine's dog (Hugh)
      // shows up on the tablets at the bottom
      paddingBottom: '250px',
    },
  },
};
