import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';

import styles from './styles';

const useStyles = makeStyles(styles);

export const StatusCard = ({ width, height, announcement, details, error }) => {
  const classes = useStyles();

  const icon = error ? <ErrorIcon /> : <InfoIcon />;
  const containerClass = error
    ? classes.errorCardContainer
    : classes.infoCardContainer;

  return (
    <div className={containerClass} style={{ width: width, height: height }}>
      <div className={classes.iconContainer}>{icon}</div>
      <div className={classes.contentContainer}>
        <div className={classes.announcement}>{announcement}</div>
        <div className={classes.details}>{details}</div>
      </div>
    </div>
  );
};

StatusCard.defaultProps = {
  error: false,
};

StatusCard.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  announcement: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  error: PropTypes.bool,
};
