import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import BoxingLineEscalationForm from './BoxingLineEscalationForm';

const BoxingLineEscalation = ({ session }) => (
  <BoxingLineEscalationForm session={session} />
);

BoxingLineEscalation.propTypes = {
  session: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BoxingLineEscalation);
