import { ROBOTO, BRIGHT_BLUE, WHITE, ERROR_CARD_RED } from 'lib/constants';

export const font = ROBOTO;
export const smallFontSize = '12px';
export const medFontSize = '14px';
export const medLargeFontSize = '16x';
export const largeFontSize = '20px';

const cardContainer = {
  display: 'flex',
  margin: '16px',
  borderRadius: '4px',
  padding: '5px',
  backgroundColor: BRIGHT_BLUE,
  color: WHITE,
  fontFamily: ROBOTO,
};

export default {
  infoCardContainer: {
    ...cardContainer,
    backgroundColor: BRIGHT_BLUE,
  },
  errorCardContainer: {
    ...cardContainer,
    backgroundColor: ERROR_CARD_RED,
  },
  iconContainer: {
    alignSelf: 'start',
    padding: '10px 2px 10px 10px',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 10px 10px 10px',
  },
  announcement: {
    fontSize: medLargeFontSize,
    fontWeight: 500,
  },
  details: {
    paddingTop: '5px',
    fontSize: medFontSize,
    fontWeight: 400,
  },
};
