import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Collapse, Table, TableBody, TableCell, TableRow } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import IngredientMealsDetails from './IngredientMealsDetails';
import styles from './styles';
import TotalProgress from '../TotalProgress';

const useStyles = makeStyles(styles);

const IngredientForPortioning = ({ ingredient, expandAll, trigger }) => {
  const classes = useStyles();
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    setExpand(expandAll);
  }, [expandAll, trigger]);

  return (
    <>
      <TableRow
        className={classes.row}
        onClick={() => {
          setExpand(!expand);
        }}
      >
        <TableCell className={classes.header}>
          {`${ingredient.name} - ${ingredient.id}`}
          <br />
          {!expand ? (
            <Button component={Link} className={classes.toggleDetailsLink}>
              VIEW DETAILS
            </Button>
          ) : (
            <Button component={Link} className={classes.toggleDetailsLink}>
              VIEW LESS
            </Button>
          )}
        </TableCell>
        <TableCell>
          <TotalProgress item={ingredient} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan="2" className={classes.collapseContainer}>
          <Collapse in={expand}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.ingredientDetailsContainer}>
                    <IngredientMealsDetails ingredient={ingredient} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default IngredientForPortioning;
