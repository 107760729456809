import React from 'react';
import times from 'lodash/times';
import sample from 'lodash/sample';
import makeStyles from '@mui/styles/makeStyles';

import styles from './styles';

const useStyles = makeStyles(styles);

const Winter = () => {
  const classes = useStyles();

  const snowflakes = [
    classes.snowflake0,
    classes.snowflake1,
    classes.snowflake2,
  ];

  return (
    <div>
      <div>
        {times(25, (i) => {
          return <div key={i} className={sample(snowflakes)} />;
        })}
      </div>
      <div className={classes.skatingPenguin} />
      <div className={classes.willie} />
      <div className={classes.winterTree} />
      <div className={classes.snowMan} />
    </div>
  );
};

export default Winter;
