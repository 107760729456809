// Rotation Codes
const PROD_MAN = '11-Prod/Man';
const PACK = '13-Pack';
export const RCV = '1-Rcv';
const BB_SB = '15-BB/SB';
const EXP_USE_BY = '17-Exp/Use By';

export const WAREHOUSE_SHELF_LIFE_TYPES = [
  PROD_MAN,
  PACK,
  RCV,
  BB_SB,
  EXP_USE_BY,
];
export const TODAY_DATE_ROTATION_TYPES = [RCV];
export const FUTURE_DATE_ROTATION_TYPES = [BB_SB, EXP_USE_BY];
export const PAST_DATE_ROTATION_TYPES = [PROD_MAN, PACK];

export const DEFAULT_PACKAGING_SHELF_LIFE_TYPE = RCV;

export const IMPOSSIBLE_UOM_ID = 0;

// segments out the Lot id and the position based on the following formats ( D104.14, 16-08-E-02 )
export const SLOT_REGEX = /(?:\.|-)([^-|^.]+)$/;

export const DELETION_REASONS = [
  'Short Date',
  'Over Purchased',
  'Incorrectly Rotated',
  'Damaged',
  'Temp Abuse',
  'Vendor Issue',
  'Other',
];

export const DELETION_REASONS_WITH_TYPE = DELETION_REASONS.map((reason) => {
  return { type: reason };
});

export const PUTBACK_REASONS = [
  {
    type: 'Need training',
  },
  {
    type: 'Do not like new feature',
  },
  {
    type: 'No Move License Plate',
  },
  {
    type: 'Other',
  },
];

// Trucks
export const TRUCK_TRAILER_SEAL_OPTIONS = [
  { type: 'Seal Present, matches BOL', internal_id: 2 },
  { type: 'No Seal', internal_id: 1 },
  { type: 'Drop Shipment', internal_id: 5 },
  { type: 'Seal Present, does not match BOL', internal_id: 3 },
  { type: 'Padlock present and locked', internal_id: 4 },
];

export const CRITICAL_DEFECTS_YES = 1;
export const CRITICAL_DEFECTS_NO = 2;

export const CRITICAL_DEFECT_MAPPING = {
  [CRITICAL_DEFECTS_YES]: 'Y',
  [CRITICAL_DEFECTS_NO]: 'N',
};

export const WAREHOUSE_DELETION_OPTIONS = [
  { type: 'Disposal', reasons: DELETION_REASONS },
  { type: 'Donation', reasons: DELETION_REASONS },
  { type: 'Receiving Error' },
  { type: 'Lost Pallet' },
  { type: 'Return to Vendor' },
  { type: 'Resell', reasons: DELETION_REASONS },
  { type: 'Count Adjustment' },
  { type: 'Transfer' },
];

export const QUANTITY_REMOVAL_OPTIONS = [
  { type: 'Issue to Production' },
  { type: 'Disposal', reasons: DELETION_REASONS_WITH_TYPE },
  { type: 'Donation', reasons: DELETION_REASONS_WITH_TYPE },
  { type: 'Cycle Count' },
];

export const PUTBACK_TYPE = 'Putback';

export const QUANTITY_INCREASE_OPTIONS = [
  {
    type: PUTBACK_TYPE,
    reasons: PUTBACK_REASONS,
    subReasonText:
      'All putbacks should be completed using the new feature. Why are you not using the new putback feature?',
  },
  { type: 'Cycle Count' },
  { type: 'Found Inventory' },
];

export const WASTE_CORRECTION = 'Waste Correction';

const PROCUREMENT_REASONS = [
  'Hold for Upcoming',
  'Vendor Issue',
  'Bad Brand',
  'Pending Transfer',
  'Pending Return',
  'Safety Stock',
];

const FSQA_REASONS = [
  'Temp Abuse',
  'Quality',
  'Short Date',
  'No Date',
  'Allergen',
  'Out of Spec',
];

const INVENTORY_CONTROL_REASONS = ['UoM', 'Date', 'Brand', 'Incorrect Product'];

export const WAREHOUSE_HOLD_OPTIONS = [
  { type: 'Procurement', reasons: PROCUREMENT_REASONS },
  { type: 'FSQA', reasons: FSQA_REASONS },
  { type: 'Inventory Control', reasons: INVENTORY_CONTROL_REASONS },
  { type: 'Pending Disposal', reasons: DELETION_REASONS },
];

export const WAREHOUSE_AUDIT_ACTIONS = [
  { type: 'Confirm Location' },
  { type: 'Issued to Production' },
  { type: 'Unknown Location' },
];

// these 2 statuses are also used for Binventory
export const DEACTIVATED = 'deactivated';
export const ACTIVE = 'active';

export const ON_HOLD = 'on hold';
export const UNKNOWN_LOCATION = 'Unknown Location';

export const FOUND_LOST_PALLET = 'Found Lost Pallet';

export const PACKAGING_ORDER_TYPE = 'Packaging';

export const APPROVAL_REASONS = ['Forecast Variability', 'Regular Shipment'];

// printer descriptions
export const BRIDGEVIEW_PRINTER_DESCR = 'pallet_license_plate_bridgeview';
export const PACKAGING_PRINTER_DESCR = 'pallet_license_plate_packaging';
export const BASIC_PRINTER_DESCR = 'pallet_license_plate';
export const INGREDIENT_PRINTER_DESCR = 'pallet_license_plate_ingredient';

// Photo categories
export const FSQA_LOT_CODE = 'fsqa_lot_code';
export const FSQA_DEFECT = 'fsqa_defect';
export const PROP_12 = 'fsqa_prop12';

// FSQA
export const BRAND_CORRECT = 'BRAND_CORRECT';
export const INITIAL_BRAND = 'initial_brand';
export const ADJUSTED_BRAND = 'adjusted_brand';
export const PRODUCT_DATE_CODE_CORRECT = 'PRODUCT_DATE_CODE_CORRECT';
export const HAS_DEFECTS = 'HAS_DEFECTS';
export const INITIAL_PRODUCT_DATE = 'initial_product_date';
export const INITIAL_PRODUCT_DATE_TYPE = 'initial_product_date_type';
export const INITIAL_DEFECTS = 'initial_defects';
export const ADJUSTED_PRODUCT_DATE = 'adjusted_product_date';
export const ADJUSTED_PRODUCT_DATE_TYPE = 'adjusted_product_date_type';
export const LOT_CODES = 'lot_codes';
export const DEFECTS = 'defects';
export const NUMBER_DEFECTIVE = 'number_defective';
export const NUMBER_SAMPLED = 'defects_sample_size';
export const DEFECT_IDS = 'defect_ids';
export const PACKAGING_ITEM = 'PackagingItem';
export const INGREDIENT = 'Ingredient';
export const INITIAL_ALLERGENS = 'initial_allergens';
export const ADJUSTED_ALLERGENS = 'adjusted_allergens';
export const EXPECTED_ALLERGENS = 'expected_allergens';
export const SELECTED_ALLERGENS = 'selected_allergens';
export const ALLERGENS_LISTED = 'allergens_listed';
export const TRUE_ALLERGENS_CORRECT = 'true allergens correct';
export const INTOLERANCES_CORRECT = 'intolerances correct';
export const FSQA_REVIEW_ALLERGENS = 'fsqa_review_allergens_attributes';
export const TEMPS = 'temps';
export const WEIGHT_UOM = 'weight_uom';
export const DEFAULT_WEIGHT_UOM = 'ounces';
export const WEIGHTS = 'weights';
export const REJECTED_QUANTITY = 'rejected_quantity';
export const FINAL_QUANTITY = 'final_quantity';
export const TEMP_INPUT_DECIMAL_PLACES = 1;
export const WEIGHT_INPUT_DECIMAL_PLACES = 2;
export const NUMBER_TEMPS_REQUIRED = 5;
export const NUMBER_WEIGHTS_REQUIRED = 9;
export const FULL_CHECK = 'full_check';
export const TIME_RANGE_SECONDS = 8 * 60 * 60; // default 8 hours
export const TRUE_ALLERGENS_GROUP_ID = 'allergens';
export const ITEM_LABELED = 'item_labeled';
export const ITEM_ALLERGENS_CORRECT = 'item_allergens_correct';
export const ITEM_NAME_CORRECT = 'item_name_correct';
export const YES_BUTTON_VALUE = true;
export const NO_BUTTON_VALUE = false;
export const NULL_BUTTON_VALUE = null;

// Audit
export const APPROVED = 'Approved';
export const FLAGGED = 'Flagged';
export const AUDIT = 'audit';
export const AUDITED = 'Audited';
export const NEEDS_AUDIT = 'Needs Audit';

// Move Requests
export const CANCELLED = 'Cancelled';
export const COMPLETED = 'Completed';
export const PARTIALLY_COMPLETED = 'Partially Completed';
export const TO_DO = 'To Do';
export const IN_PROGRESS = 'In Progress';
export const ACKNOWLEDGED = 'Acknowledged';

// Cancellation Reasons
export const ACCIDENTAL_REQUEST = 'accidental request';
export const KNOWN_SHORT = 'known short';
export const ON_FLOOR = 'on floor';
export const NOT_FOUND = 'not found';
export const PENDING_RECEIPT = 'pending receipt';
export const OTHER = 'other';
export const NO_BARCODE = 'no barcode';
// ON_HOLD declared above;

// Generic assign/unassign
export const ASSIGN = 'assign';
export const UNASSIGN = 'unassign';

// Item Putbacks
export const FULL_PALLET = 'full_pallet';
export const CASES = 'cases';
export const IN_PROGRESS_PUTBACK = 'in_progress';

// Move Type
export const CANCEL_MOVE_TYPE = 'cancel';
export const CHANGE_DROP = 'change_drop';
export const DROP_OFF = 'drop_off';
export const PICK_UP = 'pick_up';
export const PICK_UP_ONLY = 'pick_up_only';

//Request Categories
export const LABELED_PACKAGING = 'Labeled Packaging';
export const PORTIONED_INGREDIENTS = 'Portioned Ingredients';
export const COMPOUND_INGREDIENT = 'Compound Ingredient';
export const DISPOSABLE_ITEM = 'Disposable Item';

//Common Categories
export const BULK = 'Bulk';
export const PORTION = 'Portion';
export const RECIPE = 'Recipe';
export const STAGING = 'Staging';

// Drop List Filters
export const DROP_LIST_FILTER_ONLY = 1;
export const DROP_LIST_FILTER_NONE = 2;
export const DROP_LIST_FILTER_OPTIONS = [
  { name: 'Drop List Only', id: DROP_LIST_FILTER_ONLY },
  { name: 'No Drop List', id: DROP_LIST_FILTER_NONE },
];

// Dock Appointments
export const TIME_INTERVALS = [
  { value: '30', text: '30 minutes' },
  { value: '60', text: '1 hour' },
  { value: '90', text: '1 hour 30 minutes' },
  { value: '120', text: '2 hours' },
  { value: '150', text: '2 hours 30 minutes' },
  { value: '180', text: '3 hours' },
  { value: '210', text: '3 hours 30 minutes' },
  { value: '240', text: '4 hours' },
];

export const TIME_HOURS = [
  { value: '1', text: '1' },
  { value: '2', text: '2' },
  { value: '3', text: '3' },
  { value: '4', text: '4' },
  { value: '5', text: '5' },
  { value: '6', text: '6' },
  { value: '7', text: '7' },
  { value: '8', text: '8' },
  { value: '9', text: '9' },
  { value: '10', text: '10' },
  { value: '11', text: '11' },
  { value: '12', text: '12' },
];

export const TIME_MINUTES = [
  { value: '0', text: '00' },
  { value: '30', text: '30' },
];

export const TIME_MERIDIEM = [
  { value: 'AM', text: 'AM' },
  { value: 'PM', text: 'PM' },
];

// FSQA
//checked status
export const FULL_CHECK_COMPLETED = 'Completed';
export const PARTIAL_CHECK_COMPLETED = 'Partially Completed';
export const NEEDS_REVIEW = 'Needs Review';
export const NOT_COMPLETED = 'Not Completed';

// photos
export const DEFECT_PHOTOS = 'defects';
export const LOT_CODE_PHOTOS = 'lotCodes';
export const PROP_12_PHOTOS = 'prop12';
