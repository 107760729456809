import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Loading, MenuNavigation, IngredientList } from 'components/shared';

import styles from './styles';

const useStyles = makeStyles(styles);

const ActivityIngredients = ({
  activity,
  headerText,
  fetchCallback,
  requestData,
}) => {
  const { menuId } = useParams();

  const classes = useStyles();

  useEffect(() => {
    menuId && fetchCallback(menuId, activity);
  }, [menuId]);

  return (
    <div className={classes.container}>
      <MenuNavigation />
      {requestData.failed && (
        <div className={classes.error}>Failed to fetch ingredients</div>
      )}
      {requestData.fetching && <Loading fullScreen />}
      {!requestData.failed && !requestData.fetching && (
        <IngredientList
          ingredients={requestData.ingredients}
          headerText={headerText}
        />
      )}
    </div>
  );
};

ActivityIngredients.propTypes = {
  activity: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  fetchCallback: PropTypes.func.isRequired,
  requestData: PropTypes.shape({
    ingredients: PropTypes.array.isRequired,
    fetching: PropTypes.bool.isRequired,
    failed: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ActivityIngredients;
