import React from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@mui/material/Grid';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { sum, values } from 'lodash';

import {
  SMALL_PROGRESS_BAR_PIXEL_COUNT,
  COMPOUND_INGREDIENTS_ACTIVITY_ORDER,
} from 'lib/constants';

import styles from './styles';
import ProgressBar from '../ProgressBar/ProgressBar';

const useStyles = makeStyles(styles);

const TotalProgress = ({ item }) => {
  const classes = useStyles();

  const notStarted = item.notStarted || item.goalCount - item.completedCount;

  const transformedProgressActivities = () => {
    const ingredientActivityCounts = {
      completed: item.completedCount,
      notStarted,
    };
    return ingredientActivityCounts;
  };

  const renderFailedWarning = () => {
    if (item.failed > 0) {
      <p
        className={classNames(classes.itemCounts, classes.redProgressIconColor)}
      >
        <CancelOutlinedIcon
          className={classNames(
            classes.progressStepIcon,
            classes.redProgressIconColor
          )}
        />
        FAILED
      </p>;
    }
  };

  const renderOnHoldWarning = () => {
    if (item.onHold > 0) {
      return (
        <div
          className={classNames(
            classes.itemCounts,
            classes.blueProgressIconColor,
            classes.blueProgressIconPosition
          )}
        >
          <WarningAmberOutlinedIcon
            className={classNames(
              classes.progressStepIcon,
              classes.blueProgressIconColor
            )}
          />
          ON HOLD
        </div>
      );
    }
  };

  const renderReallocationWarning = () => {
    if (item.reallocatedCount > 0) {
      return (
        <div
          className={classNames(
            classes.reallocationIcon,
            classes.reallocationIconContainer
          )}
        >
          <InfoOutlinedIcon className={classNames(classes.reallocationIcon)} />
          <span>Ing. Reallocated</span>
        </div>
      );
    }
  };

  const activityOrder = COMPOUND_INGREDIENTS_ACTIVITY_ORDER;

  const sortedActivity = Object.entries(transformedProgressActivities()).sort(
    (a, b) => {
      return activityOrder.indexOf(a[0]) - activityOrder.indexOf(b[0]);
    }
  );

  const totalCount = sum(values(transformedProgressActivities()));

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
    >
      <ProgressBar
        activityCounts={sortedActivity}
        totalCount={totalCount}
        totalProgressBarLengthInPixels={SMALL_PROGRESS_BAR_PIXEL_COUNT}
      />
      <Grid item className={classes.itemCountsContainer}>
        <p className={classes.itemCounts}>
          {item.completedCount} complete &nbsp; | &nbsp;
          {notStarted} to goal &nbsp; | &nbsp;
          {item.goalCount} total
        </p>
        <div className={classes.progressSetpIconContainer}>
          {renderOnHoldWarning()}
          {renderFailedWarning()}
          {renderReallocationWarning()}
        </div>
      </Grid>
    </Grid>
  );
};

TotalProgress.propTypes = {
  item: PropTypes.object.isRequired,
};

export default TotalProgress;
