import React from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import isEmpty from 'lodash/isEmpty';

import { MIXING, COOKING, CHILLING } from 'lib/constants';

import styles from './styles';
import ActivityProgress from './ActivityProgress';

const useStyles = makeStyles(styles);

const IngredientDetails = ({ ingredient }) => {
  const classes = useStyles();

  const { goalCount, reallocatedCount, mixing, cooking, chilling } = ingredient;
  const sharedProps = { goalCount, reallocatedCount };

  return (
    <div className={classes.progressStepsContainer}>
      {!isEmpty(mixing) && (
        <>
          <ActivityProgress
            {...sharedProps}
            activity={mixing}
            title={MIXING.toUpperCase()}
          />
        </>
      )}
      {!isEmpty(cooking) && (
        <>
          <Divider className={classes.divider} />
          <ActivityProgress
            {...sharedProps}
            activity={cooking}
            title={COOKING.toUpperCase()}
          />
        </>
      )}
      {!isEmpty(chilling) && (
        <>
          <Divider className={classes.divider} />
          <ActivityProgress
            {...sharedProps}
            activity={chilling}
            title={CHILLING.toUpperCase()}
          />
        </>
      )}
    </div>
  );
};

IngredientDetails.propTypes = {
  ingredient: PropTypes.object.isRequired,
};

export default IngredientDetails;
