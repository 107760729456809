import { BORDER_GREY, ROBOTO, GREY_400 } from 'lib/constants';

export const font = ROBOTO;
export const smallFontSize = '12px';
export const medFontSize = '14px';
export const largeFontSize = '20px';

export default {
  tableTitle: {
    fontSize: smallFontSize,
    color: GREY_400,
  },
  container: {
    border: `1px solid ${BORDER_GREY}`,
    borderRadius: 8,
    boxShadow: 'none',
    fontFamily: font,
  },
  wrapper: {
    marginTop: '20px',
    marginLeft: '16px',
    marginRight: '16px',
  },
  controlsBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  selectPreText: {
    fontSize: '13px',
    marginRight: '10px',
    display: 'inline',
  },
  switch: {
    marginLeft: '25px',
    fontSize: '14px',
    display: 'inline',
  },
};
