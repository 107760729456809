import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';

import { fetchActivityIngredients } from 'redux/productionProgress/rth/actions';

const useActivityIngredients = () => {
  const dispatch = useDispatch();
  const { activity, menuId } = useParams();
  const { activityIngredients } = useSelector(
    (state) => state.rthProductionProgress
  );

  // only used to fetch ingredients on a page refresh
  useEffect(() => {
    isEmpty(activityIngredients) &&
      activity &&
      menuId &&
      dispatch(fetchActivityIngredients({ activity, menuId }));
  }, [menuId]);

  const getMealIdsByIngredientId = (ingredientId) => {
    const result = activityIngredients.find(
      (ai) => parseInt(ai.id) === parseInt(ingredientId)
    )?.mealIds;
    return result;
  };

  return {
    getMealIdsByIngredientId: getMealIdsByIngredientId,
    activityIngredients: activityIngredients,
  };
};

export default useActivityIngredients;
