import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { Route as ReactRouterDOMRoute, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { fetchActivityIngredients } from 'redux/productionProgress/rth/actions';
import { setTopBarContent } from 'redux/topBar/actions';
import { getHoneyTagPrinters } from 'redux/printers/actions';
import {
  MIXING,
  COOKING,
  CHILLING,
  RTH_PORTIONING,
  SLACKING,
  HOME_BUTTON,
  ACCOUNT_DROPDOWN,
} from 'lib/constants';
import BinProvider from 'lib/providers/BinProvider';
import { ActivityIngredients } from 'components/shared';

import BreadcrumbProvider from './BreadcrumbProvider';
import PrinterProvider from './PrinterProvider';
import ActivityNavigation from './ActivityNavigation';
import ActivityIngredientProgresses from './ActivityIngredientProgresses';
import MealAssembly from './MealAssembly';
import PrinterDialog from './PrinterDialog';
import Binventory from './Binventory';

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);

const INGREDIENT = 'Ingredient';
const COMPOUND_INGREDIENT = 'Compound Ingredient';

const RthProductionSubmissions = ({ history }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setTopBarContent({
        leftContent: HOME_BUTTON,
        rightContent: ACCOUNT_DROPDOWN,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(getHoneyTagPrinters());
  }, []);

  const fetchCallback = (menuId, activity) => {
    dispatch(fetchActivityIngredients({ menuId, activity }));
  };

  const {
    activityIngredients,
    activityIngredientsFetching: fetching,
    activityIngredientsFailed: failed,
  } = useSelector((state) => state.rthProductionProgress);

  const requestData = {
    ingredients: activityIngredients,
    fetching: fetching,
    failed: failed,
  };

  return (
    <BreadcrumbProvider>
      <PrinterProvider>
        <BinProvider>
          <Route
            path={['/rth-production-submissions']}
            render={() => <ActivityNavigation history={history} />}
          />
          <Route
            exact
            path={['/rth-production-submissions']}
            render={() => (
              <Redirect to="/rth-production-submissions/slacking" />
            )}
          />
          <Route
            exact
            path={[
              '/rth-production-submissions/slacking',
              '/rth-production-submissions/slacking/menu/:menuId',
            ]}
            render={() => (
              <ActivityIngredients
                activity={SLACKING}
                headerText={INGREDIENT}
                fetchCallback={fetchCallback}
                requestData={requestData}
              />
            )}
          />
          <Route
            exact
            path={[
              '/rth-production-submissions/mixing',
              '/rth-production-submissions/mixing/menu/:menuId',
            ]}
            render={() => (
              <ActivityIngredients
                activity={MIXING}
                headerText={COMPOUND_INGREDIENT}
                fetchCallback={fetchCallback}
                requestData={requestData}
              />
            )}
          />
          <Route
            exact
            path={[
              '/rth-production-submissions/cooking',
              '/rth-production-submissions/cooking/menu/:menuId',
            ]}
            render={() => (
              <ActivityIngredients
                activity={COOKING}
                headerText={COMPOUND_INGREDIENT}
                fetchCallback={fetchCallback}
                requestData={requestData}
              />
            )}
          />
          <Route
            exact
            path={[
              '/rth-production-submissions/chilling',
              '/rth-production-submissions/chilling/menu/:menuId',
            ]}
            render={() => (
              <ActivityIngredients
                activity={CHILLING}
                headerText={COMPOUND_INGREDIENT}
                fetchCallback={fetchCallback}
                requestData={requestData}
              />
            )}
          />
          <Route
            exact
            path={[
              '/rth-production-submissions/portioning',
              '/rth-production-submissions/portioning/menu/:menuId',
            ]}
            render={() => (
              <ActivityIngredients
                activity={RTH_PORTIONING}
                headerText={INGREDIENT}
                fetchCallback={fetchCallback}
                requestData={requestData}
              />
            )}
          />
          <Route
            path={[
              '/rth-production-submissions/:activity/menu/:menuId/ingredients/:ingredientId',
            ]}
            render={() => <ActivityIngredientProgresses />}
          />
          <Route
            exact
            path={[
              '/rth-production-submissions/assembly',
              '/rth-production-submissions/assembly/menu/:menuId',
            ]}
            render={() => <MealAssembly />}
          />
          <Route
            exact
            path={[
              '/rth-production-submissions/binventory',
              '/rth-production-submissions/binventory/menu/:menuId',
            ]}
            render={() => <Binventory />}
          />
          <PrinterDialog />
        </BinProvider>
      </PrinterProvider>
    </BreadcrumbProvider>
  );
};

RthProductionSubmissions.propTypes = {
  history: PropTypes.object.isRequired,
};

export default RthProductionSubmissions;
