import {
  BORDER_GREY,
  GREY_800,
  ROBOTO,
  GREY_150,
  GREY_600,
} from 'lib/constants';

// Re-usable styles across this specific app
export const arrowIcon = {
  fontSize: 16,
};

export const cell = {
  color: GREY_800,
  fontFamily: ROBOTO,
  fontSize: '14px',
  fontWeight: '400',
  padding: '16px',
};

export const cellLast = {
  ...cell,
  textAlign: 'right',
};

export const header = {
  ...cell,
  fontSize: '12px',
  textTransform: 'uppercase',
  letterSpacing: '1px',
  color: GREY_600,
};

export const zebraRow = {
  '&:nth-child(odd)': {
    background: `${GREY_150}`,
  },
  '&:last-child td, &:last-child th': { border: 0 },
};

export const clickableRows = {
  '&:hover': {
    cursor: 'pointer',
  },
};

export default {
  arrowIcon,
  cell,
  cellLast,
  container: {
    border: `1px solid ${BORDER_GREY}`,
    borderRadius: 8,
    boxShadow: 'none',
  },
  header,
  zebraRow,
  clickableRows,
};
