import {
  GREY_700,
  GREY_500,
  GREY_400,
  TRANQUIL_BLUE,
  ERROR_RED,
} from 'lib/constants';

export default {
  itemCountsContainer: {
    width: '100%',
  },
  itemCounts: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'flex-start',
    fontSize: '15px',
    height: '16px',
    margin: '12px 6px 6px 0px',
    color: GREY_500,
  },
  progressSetpIconContainer: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'flex-start',
  },
  progressStepIcon: {
    display: 'inline-block',
    fontSize: '15px',
    paddingRight: '5px',
    marginTop: '2px',
  },
  blueProgressIconColor: {
    color: TRANQUIL_BLUE,
  },
  redProgressIconColor: {
    color: ERROR_RED,
  },
  blueProgressIconPosition: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: '20px',
  },
  overageIcon: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '15px',
    paddingRight: '5px',
    color: GREY_400,
  },
  reallocationIconContainer: {
    color: GREY_700,
    display: 'flex',
    alignItems: 'center',
  },
  reallocationIcon: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '15px',
    paddingRight: '5px',
    color: GREY_700,
  },
};
