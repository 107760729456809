import React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import withStyles from '@mui/styles/withStyles';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

import { PHONE_SCREEN } from 'lib/constants';
import {
  BoxingLineEscalationIcon,
  BoxRayIcon,
  DockSchedulerIcon,
  InventoryIcon,
  LabelingIcon,
  MealBaggingIcon,
  PersonnelTrackingIcon,
  PortioningIcon,
  ProductionInventoryIcon,
  ProductionSheetsIcon,
  SwatSpotIcon,
  ProgressIcon,
  QAPersonnelTrackingIcon,
  RealtimeIcon,
  WarehouseReceivingIcon,
  WarehouseInventoryIcon,
  WarehouseRequestsIcon,
  WarehouseMovesIcon,
  CycleCountingIcon,
  DropZoneAndPodManagementIcon,
  TruckLoadingIcon,
  RthSubmissionsIcon,
  RthProgressTrackerIcon,
} from 'components/icons';

import styles from './styles';

function iconComponent(icon, className) {
  switch (icon) {
    case 'boxingLineEscalationIcon':
      return <BoxingLineEscalationIcon className={className} />;
    case 'portioning':
      return <PortioningIcon className={className} />;
    case 'mealBagging':
      return <MealBaggingIcon className={className} />;
    case 'labeling':
      return <LabelingIcon className={className} />;
    case 'dropZoneAndPodManagement':
      return <DropZoneAndPodManagementIcon className={className} />;
    case 'progress':
      return <ProgressIcon className={className} />;
    case 'inventory':
      return <InventoryIcon className={className} />;
    case 'realtime':
      return <RealtimeIcon className={className} />;
    case 'personnelTracking':
      return <PersonnelTrackingIcon className={className} />;
    case 'qaPersonnelTracking':
      return <QAPersonnelTrackingIcon className={className} />;
    case 'receiving':
      return <WarehouseReceivingIcon className={className} />;
    case 'warehouseInventory':
      return <WarehouseInventoryIcon className={className} />;
    case 'warehouseRequests':
      return <WarehouseRequestsIcon className={className} />;
    case 'productionInventory':
      return <ProductionInventoryIcon className={className} />;
    case 'productionSheets':
      return <ProductionSheetsIcon className={className} />;
    case 'swatSpot':
      return <SwatSpotIcon className={className} />;
    case 'boxRayIcon':
      return <BoxRayIcon className={className} />;
    case 'dockScheduler':
      return <DockSchedulerIcon className={className} />;
    case 'warehouseMoves':
      return <WarehouseMovesIcon className={className} />;
    case 'cycleCounting':
      return <CycleCountingIcon className={className} />;
    case 'truckLoading':
      return <TruckLoadingIcon className={className} />;
    case 'rthSubmissions':
      return <RthSubmissionsIcon className={className} />;
    case 'rthProgress':
      return <RthProgressTrackerIcon className={className} />;
  }
}

const AppLinkCard = (props) => {
  const {
    label,
    link,
    icon,
    classes,
    isExternal,
    isWIP,
    isNewTab,
    abbrvLabel,
  } = props;
  const frame = isNewTab ? '_blank' : '_self';
  const phoneScreen = useMediaQuery(PHONE_SCREEN);

  const innerCard = (
    <Card className={classes.card}>
      {isWIP && <div className={classes.wip}>WIP</div>}
      <div className={classes.cardContent}>
        {iconComponent(icon, classes.icon)}
        <span className={classes.label}>
          {phoneScreen && abbrvLabel ? abbrvLabel : label}
        </span>
      </div>
    </Card>
  );

  return isExternal ? (
    <a href={link} target={frame}>
      {innerCard}
    </a>
  ) : (
    <Link to={link}>{innerCard}</Link>
  );
};

AppLinkCard.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  isExternal: PropTypes.bool,
  isWIP: PropTypes.bool,
  isNewTab: PropTypes.bool,
  abbrvLabel: PropTypes.string,
};

AppLinkCard.defaultProps = {
  isExternal: undefined,
  isWIP: undefined,
  isNewTab: undefined,
  abbrvLabel: '',
};

export default withStyles(styles)(AppLinkCard);
