import React from 'react';
import PropTypes from 'prop-types';

import { HC_GREEN } from 'lib/constants';

const BoxingLineEscalationIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="80px"
        height="80px"
        viewBox="0 0 300 300"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)"
          fill={HC_GREEN}
          stroke="none"
        >
          <path
            d="M1540 3650 c-99 -19 -135 -61 -262 -310 -58 -113 -156 -304 -218
-425 -62 -121 -156 -305 -210 -410 -54 -104 -149 -291 -213 -415 -63 -124
-158 -309 -211 -413 -90 -175 -96 -191 -96 -247 0 -100 45 -175 135 -223 l40
-22 1035 -3 c569 -2 1058 0 1086 3 123 15 217 117 217 237 0 65 1 63 -271 593
-70 138 -197 385 -281 550 -389 761 -500 975 -524 1007 -41 56 -150 94 -227
78z m109 -157 c16 -16 72 -111 124 -213 52 -102 147 -288 212 -415 65 -126
164 -320 220 -430 56 -110 158 -308 225 -440 68 -132 158 -308 201 -392 91
-176 95 -199 45 -249 l-34 -34 -1057 0 -1057 0 -34 34 c-45 45 -44 73 9 177
24 46 108 210 187 364 159 312 306 600 445 870 51 99 152 297 225 440 75 149
145 272 161 288 20 19 40 27 64 27 24 0 44 -8 64 -27z"
          />
          <path
            d="M1445 3030 c-17 -19 -17 -31 -7 -207 7 -104 17 -298 22 -433 6 -135
13 -255 15 -267 9 -42 32 -53 110 -53 78 0 101 11 110 53 2 12 14 217 25 455
21 412 21 434 4 453 -15 17 -31 19 -139 19 -109 0 -124 -2 -140 -20z"
          />
          <path
            d="M1467 1882 c-14 -15 -17 -40 -17 -125 0 -134 3 -137 138 -137 86 0
95 2 118 25 25 25 26 30 22 126 -4 128 -5 129 -143 129 -86 0 -104 -3 -118
-18z"
          />
        </g>
      </svg>
    </div>
  );
};

BoxingLineEscalationIcon.propTypes = {
  className: PropTypes.string,
};

BoxingLineEscalationIcon.defaultProps = {
  className: 'BoxingLineEscalationIcon',
};

export default BoxingLineEscalationIcon;
