import React from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@mui/material/Grid';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { sum, values } from 'lodash';

import {
  LARGE_PROGRESS_BAR_PIXEL_COUNT,
  COMPOUND_INGREDIENTS_ACTIVITY_ORDER,
} from 'lib/constants';

import styles from './styles';
import ProgressBar from '../ProgressBar/ProgressBar';

const useStyles = makeStyles(styles);

const ActivityProgress = ({ title, goalCount, activity, reallocatedCount }) => {
  const classes = useStyles();
  const { failed, ...nonFailedStatuses } = activity;

  const { completed, notStarted, inProgress, onHold } = nonFailedStatuses;
  const totalCount = sum(values(nonFailedStatuses));
  const overGoal =
    goalCount - completed > 0 ? 0 : Math.abs(goalCount - completed);

  const open = sum([inProgress, onHold]);
  const activityOrder = COMPOUND_INGREDIENTS_ACTIVITY_ORDER;

  const sortedActivity = Object.entries(nonFailedStatuses).sort((a, b) => {
    return activityOrder.indexOf(a[0]) - activityOrder.indexOf(b[0]);
  });

  return (
    <Grid container spacing={0.5} className={classes.progressStep}>
      <Grid item xs={10} className={classes.progressStepContainer}>
        <div className={classes.titleContainer}>
          <span className={classes.title}>{title}</span>
        </div>
        <div className={classes.progressBarContainer}>
          <ProgressBar
            activityCounts={sortedActivity}
            totalCount={totalCount}
            totalProgressBarLengthInPixels={LARGE_PROGRESS_BAR_PIXEL_COUNT}
          />
        </div>
        <div className={classes.completedContainer}>
          <span className={classes.completedText}>
            {completed}/{goalCount} Complete
          </span>
        </div>
      </Grid>
      <Grid item xs={3} className={classes.progressCountsContainer}>
        <div className={classes.progressStepText}>
          <Brightness1Icon
            className={classNames(
              classes.progressStepIcon,
              classes.yellowStepIcon
            )}
          />
          <div className={classes.progressCount}>
            <span>Not Started</span>
            <span>{notStarted}</span>
          </div>
        </div>

        {inProgress >= 0 && (
          <div className={classes.progressStepText}>
            <Brightness1Icon
              className={classNames(
                classes.progressStepIcon,
                classes.lightGreenStepIcon
              )}
            />
            <div className={classes.progressCount}>
              <span>In Progress</span>
              <span>{open}</span>
            </div>
          </div>
        )}
        <div className={classes.progressStepText}>
          <Brightness1Icon
            className={classNames(
              classes.progressStepIcon,
              classes.darkGreenStepIcon
            )}
          />
          <div className={classes.progressCount}>
            <span>Complete</span>
            <span>{completed}</span>
          </div>
        </div>
      </Grid>

      <Grid item xs={3} className={classes.mixingStepContainer}>
        <div>
          {overGoal > 0 && (
            <div
              className={classNames(
                classes.progressStepIcon,
                classes.progressStepIconContainer
              )}
            >
              <InfoOutlinedIcon
                className={classNames(
                  classes.progressStepIcon,
                  classes.greyStepIcon
                )}
              />
              <span>{overGoal} over goal</span>
            </div>
          )}
        </div>

        <div>
          {reallocatedCount > 0 && (
            <div
              className={classNames(
                classes.progressStepIcon,
                classes.progressStepIconContainer
              )}
            >
              <InfoOutlinedIcon
                className={classNames(
                  classes.progressStepIcon,
                  classes.greyStepIcon
                )}
              />
              <span>{reallocatedCount} reallocated</span>
            </div>
          )}
        </div>

        {onHold > 0 && (
          <div
            className={classNames(
              classes.progressStepIcon,
              classes.blueStepIcon
            )}
          >
            <WarningAmberOutlinedIcon
              className={classNames(
                classes.progressStepIcon,
                classes.blueStepIcon
              )}
            />
            <span>{onHold} ON HOLD</span>
          </div>
        )}

        {failed > 0 && (
          <div
            className={classNames(
              classes.progressStepIcon,
              classes.redStepIcon,
              classes.statusAlignment
            )}
          >
            <CancelOutlinedIcon
              className={classNames(
                classes.progressStepIcon,
                classes.redStepIcon
              )}
            />
            <span>{failed} FAILED</span>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

ActivityProgress.propTypes = {
  goalCount: PropTypes.number.isRequired,
  reallocatedCount: PropTypes.number.isRequired,
  activity: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default ActivityProgress;
